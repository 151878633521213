import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "sending-sms"
    }}>{`Sending SMS`}</h1>
    <p>{`E-mail Endpoint: mail2sms.anymessage.cloud `}</p>
    <br />
    <h3 {...{
      "id": "e-mail-format"
    }}>{`E-mail format`}</h3>
    <p>{`You will have to send an SMS from a whitelisted Domain/IP to the Gateway. Upon validation the following conversion of the submitted E-mail will be performed:`}</p>
    <p>{`SAMPLE E-mail: `}</p>
    <pre><code parentName="pre" {...{}}>{`FROM: account@yourwhitelistedIP.com 
TO: 1234567890@mail2sms.anymessage.cloud 
SUBJECT: AnyMsgGW 

This is the AnyMessage E-Mail to SMS GW. 
`}</code></pre>
    <p>{`The above E-mail will result in an SMS arriving as follows on the mobile phone: `}</p>
    <ul>
      <li parentName="ul">{`SMS Originator/TPOA: AnyMsgGW `}</li>
      <li parentName="ul">{`Destination Phone:+1 234 567 8901 `}</li>
      <li parentName="ul">{`SMS Text:This is the AnyMessage E-Mail to SMS GW. `}</li>
    </ul>
    <p>{`The destination number is without a leading plus sign or leading zeros. `}</p>
    <br />
    <h3 {...{
      "id": "default-datacoding"
    }}>{`Default Datacoding`}</h3>
    <p>{`The systems supports the following Datacodings: `}</p>
    <ul>
      <li parentName="ul">{`ASCII  `}</li>
      <li parentName="ul">{`Latin-15 `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      